<template>
  <Form>
    <Field
      v-for="link in links"
      :key="link.label"
      :label="link.label"
      @click="handleClick(link)"
    >
      <template #input>
        <div class="content">
          <div class="placeholder" v-if="placeholder">{{placeholderText || '请选择'}}</div>
          <img
            class="arrow-right"
            src="../../assets/images/common/right-arrow.png"
          />
        </div>
      </template>
    </Field>
  </Form>
</template>
<script>
import { Form, Field } from "vant";
export default {
  components: {
    Form,
    Field,
  },
  props: {
    links: {
      type: Array,
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
    placeholderText: {
      type: String,
      default: '',
    }
  },
  methods: {
    handleClick(link) {
      link.event ? this.$emit(link.event, link) : this.navigate(link.path);
    },
    navigate(path) {
      this.$router.push({ path });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-cell.van-field {
  padding: 8px 16px 7px 24px;

  .van-cell__title.van-field__label {
    font-size: 14px;
    font-weight: 400;
    color: #818181;
    width: auto;
  }

  .content {
    display: flex;
    align-items: center;
    flex: auto;
    justify-content: flex-end;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #2c2c2c;
    .placeholder {
      font-size: 14px;
      color: #cecece;
      margin-right: 7px;
    }
    .arrow-right {
      width: 14px;
      height: 14px;
      margin-left: 4px;
    }
  }
}
</style>
