<template>
  <div class="result-wrap">
    <!-- 拜访小结 -->
    <div class="result-input">
      <div class="title"><span class="required">*</span>拜访小结</div>
      <Field
        class="field"
        v-model="result"
        rows="3"
        autosize
        type="textarea"
        maxlength="200"
        placeholder="说点什么把..."
        show-word-limit
      />
    </div>
    <!-- 语音 -->
    <div class="result-voice">
      <div class="title">语音</div>
      <div class="voice-text">
        <Recorder ref="recorder"> </Recorder>
      </div>
    </div>
    <!-- 现场拍照 -->
    <div class="image-viewer-wrap mb-12">
      <div class="title">现场拍照</div>
      <ImageViewer ref="imageViewer" :upload="true" :limit="9" />
    </div>
    <Navigation ref="navigation" :navigation-item="navigationItem" />
    <FooterButton :loading="loading" @click="submit">提交</FooterButton>
  </div>
</template>
<script>
import { Field } from "vant";
import FooterButton from "@/components/common/button/FooterButton.vue";
import Recorder from "@/components/Recorder/index.vue";
import Navigation from "@/components/navigation/index.vue";
import ImageViewer from "@/components/common/imageViewer";
export default {
  components: {
    Field,
    FooterButton,
    Recorder,
    Navigation,
    ImageViewer,
  },
  data() {
    return {
      loading: false,
      result: "",
    };
  },
  props: {
    navigationItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  activated() {
    this.result = "";
  },
  methods: {
    // 提交
    async submit() {
      if (!this.result) {
        this.$toast("请输入拜访小结");
        return;
      }
      if (this.$refs.recorder.isLoading) {
        this.$toast("录音正在上传,请稍后");
        return;
      }
      if (this.$refs.imageViewer.loading) {
        this.$toast("图片正在上传,请稍后");
        return;
      }
      const visitVoice = this.$refs.recorder.audioUrl;
      const visitVoiceLength = this.$refs.recorder.duration;
      const visitPhotos = this.$refs.imageViewer.tempfiles;
      this.loading = true;
      this.$emit(
        "confirm",
        {
          visitTip: this.result,
          visitVoice,
          visitVoiceLength,
          visitPhoto: (visitPhotos || [])
            .map((item) => item.uploadUrl)
            .join(","),
          visitPosition: this.$refs.navigation.value,
          visitCoordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
        },
        this.callback
      );
    },
    callback(message) {
      this.loading = false;
      if (message) {
        this.$toast(message);
      } else {
        this.$toast("提交成功");
        this.$router.back();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.result-wrap {
  .result-input,
  .result-photo,
  .result-voice {
    margin-bottom: 8px;
    padding: 10px 24px;
    text-align: left;
    background-color: #ffffff;
    .title {
      font-size: 14px;
      color: #616266;
      margin-bottom: 6px;
      .required {
        color: #cd001f;
        margin-right: 4px;
      }
    }
    .field {
      background: #f7f7f7;
      border-radius: 10px;
    }
  }
  .result-voice {
    display: flex;
    align-items: center;
    .voice-text {
      flex: auto;
      margin-left: 20px;
      height: 30px;
    }
  }
  .image-viewer-wrap {
    padding: 10px 24px 18px;
    text-align: left;
    background-color: #ffffff;
    .title {
      font-size: 14px;
      color: #616266;
      margin-bottom: 12px;
    }
  }
}
</style>
