<template>
  <div class="filter-page">
    <Header class="mb-12" />
    <Form ref="form" :rows="rows" v-model="detail" />
    <FooterButton
      v-show="hideshow"
      cancelLabel="重置"
      confirmLabel="确认"
      @cancel="reset"
      @confirm="confirm"
    />
  </div>
</template>
<script>
import Header from "@/components/header";
import FooterButton from "@/components/common/button/FooterDoubleButton.vue";
import Form from "@/components/common/form/index.vue";
export default {
  components: {
    FooterButton,
    Header,
    Form,
  },
  data() {
    return {
      hideshow: true,
      docmHeight: document.documentElement.clientHeight,  //默认屏幕高度
      showHeight: document.documentElement.clientHeight,   //实时屏幕高度

      detail: {},
      rows: [
        {
          key: "searchRegion",
          label: "区县",
          type: "picker",
          dictType: "region",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.kjTowninfo[0].fetchTown(
              this.detail.searchRegion
            );
            this.$refs.form.$refs.kjTowninfo[0].reset();
          },
        },
        {
          key: "kjTowninfo",
          label: "乡镇",
          type: "picker",
          dictType: "town",
          valueString: "id",
          labelString: "name",
        },
        {
          key: "runCompBrand",
          label: "竞品品牌",
          type: "picker",
          dictType: "crm_compete_brand",
        },
        {
          key: "plant",
          label: "种植作物",
          type: "picker",
          gap: true,
          dictType: "plantSelect",
        },
        { key: "fundPool", label: "账款金额", type: "range", unit: "元" },
        { key: "salesVolume", label: "本年销量", type: "range", unit: "吨" },
        { key: "salesCompare", label: "销售同比", type: "range", unit: "%" },
        { key: "totalCapacity", label: "容量", type: "range", unit: "吨" },
      ],
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    // window.onresize监听页面高度的变化
    window.onresize = ()=>{
      return(()=>{
        this.showHeight = document.body.clientHeight;
      })()
    }
  },
  //监听
  watch:{
    showHeight:function() {
      if(this.docmHeight > this.showHeight){
        this.hideshow=false
      }else{
        this.hideshow=true
      }
    }
  },
  activated() {
    if (!window.localStorage.getItem("shidanli-filter")) {
      this.detail = {};
    }
  },
  methods: {
    reset() {
      this.detail = {};
      // this.$emit("input", {});
    },
    confirm() {
      const {
        searchRegion,
        kjTowninfo,
        runCompBrand,
        plant,
        fundPool,
        salesCompare,
        salesVolume,
        totalCapacity,
      } = this.detail;
      const query = {
        searchRegion,
        kjTowninfo,
        runCompBrand,
        plant,
        fundPool1: fundPool ? fundPool[0] : "",
        fundPool2: fundPool ? fundPool[1] : "",
        salesCompare1: salesCompare ? salesCompare[0] : "",
        salesCompare2: salesCompare ? salesCompare[1] : "",
        salesVolume1: salesVolume ? salesVolume[0] : "",
        salesVolume2: salesVolume ? salesVolume[1] : "",
        totalCapacity1: totalCapacity ? totalCapacity[0] : "",
        totalCapacity2: totalCapacity ? totalCapacity[1] : "",
      };
      // this.$emit("input", query);
      // this.$emit("confirm");
      window.localStorage.setItem(
        "shidanli-filter",
        JSON.stringify(query || {})
      );
      this.$router.push({ path: "/terminalOutlets" });
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-page {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
}
</style>
