<template>
  <div class="common-datepicker-range">
    <div class="content" @click="openDateStartPciker">
      <div>{{ yearStart }}</div>
      <div class="date-label">年</div>
      <div>{{ monthStart }}</div>
      <div class="date-label">月</div>
      <div>{{ dayStart }}</div>
      <div class="date-label">日</div>
      <!-- <img class="date-icon" src="../../../assets/images/home/date.png" /> -->
    </div>

    <div class="split">-</div>

    <div class="content" @click="openDateEndPciker">
      <div>{{ yearEnd }}</div>
      <div class="date-label">年</div>
      <div>{{ monthEnd }}</div>
      <div class="date-label">月</div>
      <div>{{ dayEnd }}</div>
      <div class="date-label">日</div>
      <img class="date-icon" src="../../../assets/images/home/date.png" />
    </div>
    
    <Popup v-model="isShowDateStartPicker" round position="bottom">
      <DatetimePicker
        type="date"
        :min-date="new Date('1900')"
        :value="safeStart || new Date()"
        @confirm="onDateStartConfirm"
        @cancel="closeDateStartPicker"
      />
    </Popup>

    <Popup v-model="isShowDateEndPicker" round position="bottom">
      <DatetimePicker
        type="date"
        :min-date="new Date('1900')"
        :value="safeEnd || new Date()"
        @confirm="onDateEndConfirm"
        @cancel="closeDateEndPicker"
      />
    </Popup>
  </div>  
</template>

<script>
import { DatetimePicker, Popup } from "vant";

export default {
  props: {
    value: {}
  },
  components: {
    DatetimePicker,
    Popup,
  },
  computed: {
    safeStart() {
      return this.value && this.value[0] || '';
    },
    yearStart() {
      return this.safeStart ? this.safeStart.getFullYear() : "";
    },
    monthStart() {
      return this.safeStart ? `00${this.safeStart.getMonth() + 1}`.slice(-2) : "";
    },
    dayStart() {
      return this.safeStart ? `00${this.safeStart.getDate()}`.slice(-2) : "";
    },
    safeEnd() {
      return this.value && this.value[1] || '';
    },
    yearEnd() {
      return this.safeEnd ? this.safeEnd.getFullYear() : "";
    },
    monthEnd() {
      return this.safeEnd ? `00${this.safeEnd.getMonth() + 1}`.slice(-2) : "";
    },
    dayEnd() {
      return this.safeEnd ? `00${this.safeEnd.getDate()}`.slice(-2) : "";
    },
  },
  data() {
    return {
      isShowDateStartPicker: false,
      isShowDateEndPicker: false,
    };
  },
  methods: {
    reset() {
      this.$emit("input", []);
    },
    closeDateStartPicker() {
      this.isShowDateStartPicker = false;
    },
    openDateStartPciker() {
      this.isShowDateStartPicker = true;
    },
    onDateStartConfirm(date) {
      this.$emit("input", [date, this.safeEnd]);
      this.isShowDateStartPicker = false;
    },
    closeDateEndPicker() {
      this.isShowDateEndPicker = false;
    },
    openDateEndPciker() {
      this.isShowDateEndPicker = true;
    },
    onDateEndConfirm(date) {
      this.$emit("input", [this.safeStart, date]);
      this.isShowDateEndPicker = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.common-datepicker-range {
  display: flex;
  align-items: center;
  .content {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #2c2c2c;
  }
  .date-label {
    margin: 0 0px;
    color: #cecece;
  }
  .split {
    margin: 0 4px;
  }
  .date-icon {
    width: 14px;
    height: 12px;
    margin-bottom: 3px;
  }
  .date-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0.4);
  }
}
</style>