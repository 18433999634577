<template>
  <div class="voice-container">
    <div
      class="voice-wrap"
      @touchstart.stop.prevent="mouseStart"
      @touchend.stop.prevent="mouseEnd"
      @mousedown.prevent="mouseStart"
      @mouseup.prevent="mouseEnd"
    >
      <span v-if="isLoading">上传中,请稍后</span>
      <span v-else>
        {{ isRecording ? "松开结束" : "按住说话" }}
      </span>
      <audio-recorder ref="recorder" :after-recording="afterRecording" />
      <audio ref="audio" v-show="false" :src="audioUrl"></audio>
    </div>
    <div v-if="!isLoading && duration > 0" class="voice-img" @click="playAudio">
      <img :src="VoiceIcon" />
      <span>{{ duration }}'</span>
    </div>
  </div>
</template>
<script>
import { commonApi } from "@/utils/api.js";
import VoiceIcon from "@/assets/images/common/audio-red.png";
export default {
  data() {
    return {
      VoiceIcon,
      isRecording: false,
      isLoading: false,
      startTime: "", // 语音开始时间
      endTime: "", // 语音结束,
      audioUrl: "",
      duration: 0,
      // 微信语音相关
      isUseWechat: true,
      localId: "",
      serverId: "",
    };
  },
  mounted() {
    try {
      this.checkApi();
    } catch (err) {
      //
    }
    // 初始化开启录音权限
    const self = this;
    this.$nextTick(() => {
      try {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        navigator.getUserMedia =
          navigator.getUserMedia || navigator.webkitGetUserMedia;
        window.URL = window.URL || window.webkitURL;
        if (navigator.getUserMedia) {
          navigator.getUserMedia(
            { audio: true },
            function() {},
            function() {
              self.$toast("当前设备不支持录音功能");
            }
          );
        }
      } catch (e) {
        self.$toast("当前设备不支持录音功能");
      }
    });
  },
  activated() {
    this.audioUrl = "";
    this.duration = 0;
  },
  methods: {
    // 检查权限
    checkApi() {
      window.wx.checkJsApi({
        jsApiList: ["startRecord", "stopRecord", "playVoice", "uploadVoice"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: (res) => {
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          const result = res.checkResult;
          Object.keys(result).forEach((key) => {
            if (!result[key]) {
              this.$toast(`${key}方法不支持`);
            }
          });
        },
        fail: (err) => {
          this.$toast(JSON.stringify(err));
        },
      });
    },
    //长按说话
    mouseStart() {
      if (this.isLoading) {
        return;
      }
      try {
        if (this.isUseWechat) {
          window.wx.startRecord();
        } else {
          this.$refs.recorder.$el.querySelector(".ar-icon.ar-icon__lg").click();
        }
      } catch (err) {
        this.$toast("当前设备不支持录音功能");
      }
      this.startTime = new Date().getTime();
      this.isRecording = true;
    },
    mouseEnd() {
      if (this.isLoading) {
        return;
      }
      try {
        if (this.isUseWechat) {
          window.wx.stopRecord({
            success: (res) => {
              this.localId = res.localId;
              this.uploadAudio();
            },
            fail: (err) => {
              this.$toast(JSON.stringify(err));
            },
          });
        } else {
          this.$refs.recorder.$el
            .querySelector(".ar-icon.ar-recorder__stop")
            .click();
        }
      } catch (err) {
        this.$toast(JSON.stringify(err));
      }
      this.endTime = new Date().getTime();
      this.isRecording = false;
    },
    async afterRecording(raw) {
      this.isLoading = true;
      try {
        const arr = raw.duration.split(":");
        this.duration = (Number(arr[0]) || 0) * 60 + (Number(arr[1]) || 0) + 1;
        // 上传
        const file = new window.File([raw.blob], `录音_${Date.now()}.mp3`, {
          type: raw.blob.type,
        });
        const response = await commonApi.upload({ file });
        this.audioUrl = response.data;
      } catch (err) {
        this.$toast(err.message);
      }
      this.isLoading = false;
    },
    // 播放录音
    playAudio() {
      if (this.isUseWechat) {
        window.wx.playVoice({
          localId: this.localId, // 需要播放的音频的本地ID，由stopRecord接口获得
        });
      } else {
        this.$refs.audio.play();
      }
    },
    // 上传语音
    uploadAudio() {
      this.isLoading = true;
      window.wx.uploadVoice({
        localId: this.localId, // 需要上传的音频的本地ID，由stopRecord接口获得
        isShowProgressTips: 0, // 默认为1，显示进度提示
        success: (res) => {
          this.isLoading = false;
          this.serverId = res.serverId; // 返回音频的服务器端ID
          this.getMediaUrl();
        },
        fail: () => {
          this.$toast("上传微信服务器失败");
        },
      });
    },
    // 获取微信语音URL
    async getMediaUrl() {
      if (!this.serverId) {
        this.$toast("上传微信服务器失败");
        return;
      }
      this.isLoading = true;
      try {
        const res = await commonApi.getMediaUrl({ mediaId: this.serverId });
        this.audioUrl = res.data;
        this.duration = Math.ceil((this.endTime - this.startTime) / 1000);
      } catch (err) {
        this.$toast(err.message);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.voice-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}
.voice-wrap {
  height: 100%;
  flex: auto;
  background: #f7f7f7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #818181;
}
.voice-img {
  display: flex;
  align-items: center;
  height: 24px;
  margin-left: 12px;
  img {
    height: 100%;
    width: auto;
  }
  span {
    display: inline-block;
    margin-left: 4px;
    color: #818181;
  }
}
::v-deep .ar {
  display: none;
}
</style>
