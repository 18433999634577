<template>
  <div class="plant-select-wrap">
    <Header class="header">
      <div class="search-wrap">
        <Search v-model="searchTotal" @search="resetList" />
      </div>
    </Header>
    <div style="padding: 50px 0;">
      <div class="type-container">
        <div class="cell">种植作物</div>
        <div class="cell">种植类型</div>
        <div class="cell">覆盖</div>
      </div>
      <Loading v-show="loading" style="margin-top: 160px" />
      <div v-show="!loading" class="list-container">
        <div class="row" v-for="(item, index) in list" :key="index">
          <div class="cell">{{ item.plantName }}</div>
          <div class="cell">{{ item.plantType }}</div>
          <div class="cell">
            <img
              class="check-icon"
              :src="active[item.plantId] ? CheckIcon : NotCheckIcon"
              @click="handleClick(item.plantId)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="btn-container">
      <CustomButton size="large" @click="confirm">保存</CustomButton>
    </div>
  </div>
</template>

<script>
import CheckIcon from "@/assets/images/common/circle-check.png";
import NotCheckIcon from "@/assets/images/common/not-check.png";
import CustomButton from '@/components/common/button/CustomButton.vue';
import Header from '@/components/header/index.vue';
import Search from '@/components/common/search/search.vue';
import { terminalOutletsApi } from "@/utils/api.js";
import { Loading } from "vant";
export default {
  components: {
    Header,
    Search,
    CustomButton,
    // InfiniteLoading,
    Loading,
  },
  data() {
    return {
      loading: false,
      CheckIcon,
      NotCheckIcon,
      active: {},
      list: [],
      plantName: "",
    };
  },
  async mounted() {
    const data = {
      terminalId: this.$route.params.id,
    };
    const response = await terminalOutletsApi.plantList(data);
    this.list = response.data || [];
  },
  methods: {
    handleClick(id) {
      this.$set(this.active, id, !this.active[id]);
    },
    resetList(val) {
      this.plantName = val;
      this.list = [];
      this.fetchList();
    },
    async fetchList() {
      let data = {
        "plantName": this.plantName,
        "id": this.$route.params.id,
      }
      let response = await terminalOutletsApi.plantList(data)
      this.list = this.list.concat(response.data || []);
    },
    async confirm() {
      const ids = Object.keys(this.active).filter((id) => {
        return this.active[id];
      });
      this.loading = true;
      try {
        await Promise.all(
          ids.map((id) => {
            return terminalOutletsApi.coverPlantSave({
              terminalId: this.$route.params.terminalId,
              productId: id,
            });
          })
        );
        this.$toast("保存成功");
        this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.plant-select-wrap {
  font-size: 14px;
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .header {
    position:fixed;
    .search-wrap {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 8px 54px 7px 72px;
    }
  }
  .type-container {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    color: #2c2c2c;
    background: white;
  }
  .cell {
    flex: 1;
  }
  .list-container {
    width: 100%;
    padding-top: 12px;
    .row {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      color: #818181;
      background: white;
    }
    .check-icon {
      width: 17px;
      height: 17px;
    }
  }
  .btn-container {
    position:fixed;
    bottom: 0px;
    width: 100%;
    box-sizing: border-box;
    padding: 5px ;
  }
}
</style>
