<template>
  <div>
    <Form :rows="rows.slice(0, 3)" />
    <TextArea
      class="mb-12"
      label="交办事项"
      :showLimit="false"
      :required="true"
    />
    <!-- 语音 -->
    <div class="image-viewer-wrap mb-12">
      <div class="title">现场拍照</div>
      <ImageViewer
        :upload="false"
        :images="[TestPng, TestPng, TestPng, TestPng, TestPng]"
      />
    </div>
    <Form :rows="rows.slice(3)" />
    <Navigation />
    <FooterButton>保存</FooterButton>
  </div>
</template>
<script>
import Form from "@/components/common/form/index.vue";
import ImageViewer from "@/components/common/imageViewer";
import TestPng from "@/views/shidanliBusiness/test.png";
import FooterButton from "@/components/common/button/FooterButton.vue";
import Navigation from "@/components/navigation/index.vue";
import TextArea from "@/components/common/textArea/index.vue";
export default {
  components: {
    Form,
    ImageViewer,
    Navigation,
    FooterButton,
    TextArea,
  },
  data() {
    return {
      TestPng,
      rows: [
        { key: "111", label: "检核人角色", type: "input" },
        { key: "222", label: "检核日期", type: "date" },
        { key: "333", label: "数据准确", required: true, gap: true },
        { key: "555", label: "延时指派任务", type: "switch" },
        { key: "666", label: "短信提醒负责人", type: "switch", gap: true },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.image-viewer-wrap {
  padding: 10px 24px 18px;
  text-align: left;
  background-color: #ffffff;
  position: relative;
  .title {
    font-size: 14px;
    color: #616266;
    margin-bottom: 12px;
  }
  .tag {
    position: absolute;
    right: 24px;
    top: 10px;
    font-size: 14px;
    color: #000000;
  }
}
</style>
