<template>
  <div class="input-radio-wrap">
    <Input  :unit="unit || ''" v-model="value.input" v-if="value.radio !== '否' && value.radio !== ''" type="text" />
    <Radio value="inputRadio" v-model="value.radio" :list="list || []" />
  </div>
</template>

<script>
import Input from "@/components/common/input/index.vue";
import Radio from "@/components/select/radio.vue";

export default {
  components: {
    Input,
    Radio,
  },
  props: {
    value: {}, // {input, radio}
    list: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    unit: {
      type: String,
      value: ''
    }
  },
  watch: {
    value: {
      handler() {
        this.$emit('input', this.value)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.input-radio-wrap {
  display: flex;
  align-items: center;
  ::v-deep .van-field__body {
    height: 20px;
  }
}
</style>