<template>
  <div>
    <div class="base-info mb-12">
      <div>
        <div class="count">{{ detail.meetingNum || 0 }}</div>
        <div class="label">会议召开</div>
      </div>
      <div>
        <div class="count">{{ detail.salesRank || 0 }}</div>
        <div class="label">销售排名</div>
      </div>
      <div>
        <div class="count">{{ detail.coverVillageNum || 0 }}</div>
        <div class="label">覆盖村数</div>
      </div>
      <div>
        <div class="count">{{ detail.incrOpportunity || 0 }}</div>
        <div class="label">增量空间</div>
      </div>
    </div>
    <RowLink class="mb-12" :links="links.slice(0, 6)" />
    <RowLink :links="links.slice(6)" @navigate="navigate" />
    <Navigation
      v-show="false"
      ref="navigation"
      :navigation-item="navigationItem"
    />
  </div>
</template>
<script>
import RowLink from "@/components/select/rowLink.vue";
import Navigation from "@/components/navigation/index.vue";
export default {
  components: {
    RowLink,
    Navigation,
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
    navigationItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      id: "",
    };
  },
  computed: {
    links() {
      return [
        {
          label: "作物覆盖",
          path: `/terminalOutlets/cropCover/${this.id}`,
        },
        {
          label: "乡村覆盖",
          path: `/terminalOutlets/villageCover/${this.id}`,
        },
        {
          label: "会议信息",
          path: `/meeting/meetingList?type=terminalOutlets&&id=${this.id}`,
        },
        {
          label: "查看年度客诉",
          path: `/complaint/checkComplaint?type=terminalOutlets&&id=${this.id}`,
        },
        {
          label: "查看本季度配比销量",
          path: `/sales/yearSales?type=terminalOutlets&&id=${this.id}&tab=season`,
        },
        {
          label: "查看本年度配比销量",
          path: `/sales/yearSales?type=terminalOutlets&&id=${this.id}&tab=year`,
        },
        { label: "地图导航", event: "navigate" },
        {
          label: "近期拜访记录",
          path: `/visit/list?type=terminalOutlets&&id=${this.id}&tab=shidanli`,
        },
        {
          label: "本竞品库存",
          path: `/info/storeInfo?type=terminalOutlets&&id=${this.id}`,
        },
        {
          label: "终端销量",
          path: `/sales/terminalSales?type=terminalOutlets&&id=${this.id}`,
        },
        {
          label: "账款管理",
          // path: `/bill/manage?type=terminalOutlets&&id=${this.id}`,
          path: `/bill/terminalManage/1/${this.id}`,
        },
      ];
    },
  },
  activated() {
    this.id = this.$route.params.id;
  },
  methods: {
    navigate() {
      this.$refs.navigation.navigation();
    },
  },
};
</script>
<style lang="scss" scoped>
.base-info {
  padding: 12px;
  display: flex;
  box-sizing: border-box;
  background-color: #ffffff;
  & * {
    box-sizing: border-box;
  }
  & > div {
    flex: 0 0 25%;
    padding: 0 12px;
  }
  .count {
    font-size: 23px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 8px;
  }
  .label {
    font-size: 14px;
    color: #818181;
  }
}
</style>
