<template>
  <div class="input-wrap">
    <Field
      class="field"
      :value="value"
      :type="type || 'digit'"
      :placeholder="placeholder"
      @input="handleChange"
      :disabled="disabled"
      :maxlength="maxlength"
    />
    <span class="unit">{{ unit }}</span>
  </div>
</template>
<script>
import { Field } from "vant";
export default {
  components: {
    Field,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "请输入",
    },
    unit: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 200
    }
  },
  methods: {
    handleChange(val) {
      this.$emit("input", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.input-wrap {
  display: flex;
  align-items: center;
  .field {
    width: 100px;
    height: 20px;
    background: #ffffff;
    border-radius: 5px;
    padding: 2px 8px;
    ::v-deep .van-field__body {
      height: 100%;
      .van-field__control {
        text-align: right;
      }
    }
    &::after {
      border: none;
    }
  }
  .unit {
    color: #2c2c2c;
    margin: 0 0 0 4px;
    font-size: 14px;
  }
}
</style>
