<template>
  <div class="range-input">
    <Field
      class="field"
      :value="value[0]"
      :type="type || 'number'"
      @input="handleStartChange"
    />
    <span class="divider">-</span>
    <Field
      class="field"
      :value="value[1]"
      :type="type || 'number'"
      @input="handleEndChange"
    />
    <span class="divider unit">{{ unit }}</span>
  </div>
</template>
<script>
import { Field } from "vant";
export default {
  components: {
    Field,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    unit: {
      type: String,
      default: "",
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    reset() {
      this.$emit("input", ["", ""]);
    },
    handleStartChange(val) {
      this.$emit("input", [val, this.value[1]]);
    },
    handleEndChange(val) {
      this.$emit("input", [this.value[0], val]);
    },
  },
};
</script>
<style lang="scss" scoped>
.range-input {
  display: flex;
  align-items: center;
  text-align: center;
  .field {
    width: 100px;
    height: 25px;
    background: #ffffff;
    border: 1px solid #cecece;
    opacity: 1;
    border-radius: 5px;
    padding: 2px 8px;
    ::v-deep .van-field__body {
      height: 100%;
    }
    ::v-deep .van-field__control {
      text-align: center;
    }
  }
  .divider {
    margin: 0 5px;
    color: #91939a;
    &.unit {
      margin-left: 6px;
      margin-right: 0;
      min-width: 16px;
    }
  }
}
</style>
