<template>
  <div class="search-form">
    <Field
      class="field"
      placeholder="请输入"
      size="small"
      v-model="inputValue"
    ></Field>
    <Button class="search-btn" size="small" @click="handleSearch">
      <img :src="SearchIcon" alt="" />
      <span>搜索</span>
    </Button>
  </div>
</template>

<script>
import { Field, Button } from 'vant'
import SearchIcon from "@/assets/images/common/search-icon.svg";

export default {
  components: {
    Field,
    Button
  },
  data() {
    return {
      SearchIcon,
      inputValue: ''
    }
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.inputValue)
      this.inputValue = ""
    }
  }
}
</script>

<style lang="scss" scoped>
.search-form {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 3px;

  ::v-deep .van-cell::after {
    border: none;
  }
  .field {
    background: white;
    opacity: 1;
    height: 25px;
    padding: 0;
    font-size: 12px;
    ::v-deep .van-field__body {
      height: 25px;
      .van-field__control {
        height: 100%;
      }
    }
  }
  .search-btn {
    height: 25px;
    color: #ffffff;
    background: #cd001f;
    border-radius: 5px;
    flex-shrink: 0;
    margin-left: 3px;
    font-size: 12px;
    ::v-deep .van-button__text {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: auto;
        width: 12px;
      }
      span {
        margin-left: 2px;
        transform: translateY(0.5px);
      }
    }
  }
}
</style>