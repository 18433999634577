<template>
  <div class="terminal-sales-page">
    <Header class="header">
      <div class="search-wrap">
        <Search v-model="searchTotal" @search="resetList" />
      </div>
      <div class="picker-item" style="background-color: #f5f5f5;padding-left: 44px;">村名</div>
    </Header>
    <div style="padding: 90px 0;">
    <div
            v-for="(item, index) in list"
            :key="index"
            class="picker-item"
            @click="handleClick(item.id)"
    >
      <img
              class="check-icon"
              :src="active[item.id] ? CheckIcon : NotCheckIcon"
      />
      <span style="text-align: center">{{ item.name }}</span>
    </div>
    </div>
    <div class="btn-container">
      <CustomButton size="large" @click="handldToNext">保存</CustomButton>
    </div>
  </div>
</template>

<script>
import CheckIcon from "@/assets/images/common/circle-check.png";
import NotCheckIcon from "@/assets/images/common/not-check.png";
import CustomButton from '@/components/common/button/CustomButton.vue'
import Header from '@/components/header/index.vue'
import Search from '@/components/common/search/search.vue'
import { terminalOutletsApi } from '@/utils/api'
import { getUserName } from "@/utils/user";

export default {
  components: {
    Header,
    Search,
    CustomButton,
  },
  data() {
    return {
      CheckIcon,
      NotCheckIcon,
      params: {},
      list: [],
      active: {}, // 覆盖
      id: "",
      searchTotal: "",
      villageName: ""
    }
  },
  activated() {
    this.resetList()
  },
  deactivated() {
    this.resetList()
  },
  created() {
    this.$emit('terminalId', this.$route.params.terminalId)
    this.params = this.$route.params || {}
    this.fetchList();
  },
  methods: {
    resetList(val) {
      this.villageName = val;
      this.showFilter = false;
      this.list = [];
      this.fetchList();
    },
    async fetchList() {
      let data = {
        "villageName": this.villageName,
        "id": this.params.townId
      }
      let response = await terminalOutletsApi.villageList(data)
      this.list = this.list.concat(response.data || []);
    },

    handleClick(id) {
      this.$set(this.active, id, !this.active[id]);
      let data = {
        "delFlag": this.active[id] ? "0" : "1",
        "terminalId": this.$route.params.terminalId,
        "isBoruocun": "0",
        "loginName": getUserName(),
        "villageId": id
      };
      terminalOutletsApi.coverVillageSave(data);
    },
    handldToNext() {
      this.$router.go(-2);
    }
  }
}
</script>

<style lang="scss" scoped>
.terminal-sales-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .header {
    position:fixed;
    .search-wrap {
      width: 100%;
      height: 100%;
      box-sizing: border-box; 
      padding: 8px 54px 7px 72px;
    }
  }
  .picker-item {
    padding: 12px 24px;
    text-align: center;
    background-color: #fbfbfb;
    border-top: 2px solid #ffffff;
    &.active {
      background-color: #cd001f;
      color: #ffffff;
    }
  }
}
.no-more {
  font-size: 14px;
  color: #323232;
}
.check-icon {
  width: 17px;
  height: 17px;
  float: left;
}
.btn-container {
  position:fixed;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  padding: 5px ;
}

</style>