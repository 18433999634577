<template>
  <div class="common-area">
    <div class="content" @click="openModal">
      <div class="label">{{valueStr || '请选择'}}</div>
      <img class="arrow-right" src="../../assets/images/common/right-arrow.png" />
    </div>
    <Popup v-model="isShowModal" round position="bottom">
      <Area :area-list="areaList" @confirm="onConfirm" />
    </Popup>
  </div>
</template>


<script>
import { Area, Popup} from 'vant';
import { areaList } from '@vant/area-data';

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    Area,
    Popup,
  },
  data() {
    return {
      areaList,
      isShowModal: false,
      value: [],
    }
  },
  computed: {
    valueStr() {
      return this.value && this.value.map(d => d.name).join('/');
    }
  },
  methods: {
    closeModal() {
      this.isShowModal = false;
    },
    openModal() {
      this.isShowModal = true;
    },
    onConfirm(val) {
      this.value = val;
      this.isShowModal = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.common-area {
  .content {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #2C2C2C;
    .label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #CECECE;
    }
    .arrow-right {
      width: 14px;
      height: 14px;
      margin-left: 4px;
    }
  }
  .area-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0.4);
  }
}
</style>