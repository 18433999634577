<template>
  <div class="terminal-outlets-check">
    <HeaderNavigation type="book" />
    <Tabs class="tabs">
      <Tab title="店外提示">
        <Hint></Hint>
      </Tab>
      <Tab title="基本信息">
        <BasicComponent :detail="detail" :navigation-item="navigationItem" />
      </Tab>
      <Tab title="终端建设">
        <Building />
      </Tab>
      <Tab title="交办事项">
        <Matter />
      </Tab>
    </Tabs>
  </div>
</template>
<script>
import HeaderNavigation from "@/components/header/headerWithNavigation";
import BasicComponent from "./components/Basic";
import Hint from "./components/Hint";
import Building from "./components/check/Building";
import Matter from "./components/check/Matter";
import { Tabs, Tab } from "vant";
import { terminalOutletsApi } from "@/utils/api.js";
export default {
  components: {
    HeaderNavigation,
    BasicComponent,
    Hint,
    Building,
    Matter,
    Tabs,
    Tab,
  },
  data() {
    return {
      id: "",
      detail: {},
    };
  },
  computed: {
    navigationItem() {
      const point = this.detail.coordinate || "";
      const [lng, lat] = point.split(",");
      return { lng, lat, position: this.detail.location };
    },
  },
  activated() {
    this.id = this.$route.params.id;
    this.fetchDetail();
  },
  methods: {
    async fetchDetail() {
      const response = await terminalOutletsApi.detail({ id: this.id });
      this.detail = response.data;
    },
    async submit() {},
  },
};
</script>
<style lang="scss" scoped>
.terminal-outlets-check {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  padding-bottom: 56px;
  box-sizing: border-box;
  .tabs ::v-deep .van-tabs__wrap {
    text-align: center;
    background-color: #ffffff;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 4px;
    .van-tabs__nav {
      width: 100vw;
      justify-content: space-between;
      padding-left: 24px;
      padding-right: 24px;
      .van-tab {
        font-size: 14px;
        margin: 0;
      }
    }
  }
}
</style>
