<template>
  <div class="card-wrap" @click="handleClick($event)">
    <div v-if="title" class="title">{{ title }}</div>
    <div
      class="content-item"
      v-for="item in content.filter((it) => !it.hide)"
      :key="item.label"
      :class="{ 'flex-50': item['flex-50'] }"
    >
      <span class="label">{{ item.label }}</span>
      <a v-if="item.type === 'tel'" :href="`tel:${item.value}`">{{
        item.value
      }}</a>
      <span v-else :style="{ color: item.color }">{{ item.value }}</span>
    </div>
    <div class="status-wrap" v-if="hasStatus">
      状态：
      <div class="status-text" :style="{ color: status.color }">
        {{ status.text }}
      </div>
    </div>
    <div class="divider"></div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    content: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hasStatus: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Object,
      default: () => {
        return { text: "", color: "" };
      },
    },
  },
  methods: {
    handleClick(event) {
      if (event.target.tagName.toLocaleLowerCase() !== "a") {
        this.$emit("click");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card-wrap {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
  opacity: 1;
  border-radius: 10px;
  padding: 12px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  & > div {
    flex: 0 0 100%;
  }
  .title {
    font-size: 14px;
    font-weight: 500;
    color: #2c2c2c;
    margin-bottom: 8px;
  }
  .content-item {
    display: flex;
    font-size: 14px;
    color: #2c2c2c;
    opacity: 1;
    margin-bottom: 8px;
    &.flex-50 {
      flex: 0 0 50%;
    }
    .label {
      color: #818181;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    opacity: 1;
    background-color: #eaeaea;
    margin-bottom: 8px;
  }
  .status-wrap {
    width: 120px;
    position: absolute;
    top: 13px;
    right: 10px;
    font-size: 14px;
    color: #2c2c2c;
    display: flex;
    white-space: nowrap;
  }
}
</style>
