<template>
  <div class="textarea-input">
    <div class="title" v-if="label"><span class="required" v-if="required">*</span>{{label}}</div>
    <Field
      class="field"
      :value="value"
      @input="emitInput"
      rows="3"
      autosize
      type="textarea"
      :maxlength="limit"
      :placeholder="placeholder"
      :show-word-limit="showLimit"
    />
  </div>
</template>

<script>
import { Field } from 'vant'

export default {
  components: {
    Field
  },
  props: {
    required: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '说点什么吧…'
    },
    limit: {
      type: Number,
      default: 200
    },
    showLimit: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: '',
    }
  },
  methods: {
    emitInput(e) {
      this.$emit('input', e);
    }
  },
}
</script>

<style lang="scss" scoped>
.textarea-input {
  margin-bottom: 8px;
  padding: 10px 24px;
  text-align: left;
  background-color: #ffffff;
  .title {
    font-size: 14px;
    color: #91939A;
    margin-bottom: 6px;
    .required {
      color: #cd001f;
      margin-right: 4px;
    }
  }
  .field {
    background: #f7f7f7;
    border-radius: 10px;
  }
}
</style>