<template>
  <Detail></Detail>
</template>
<script>
import Detail from "./components/Detail";
export default {
  components: {
    Detail,
  },
};
</script>
<style lang="scss" scoped></style>
